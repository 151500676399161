import { Link } from "react-router-dom";

export default function Header(){

    return(
        <>
        
        <Block1 />
     
        </>

    );

}

function Block1(){
    return(
<>


<header id="header" className="header d-flex align-items-center fixed-top">


    <div className="container-fluid container-xl position-relative d-flex align-items-center">

      <a href="" className="logo d-flex align-items-center me-auto">
        <img src="assets/img/logo2.jpg" alt="" />
        <h1 className="sitename">Doctrina-Tech</h1>
      </a>

      <nav id="navmenu" className="navmenu">
        <ul>
          <li><a href="" className="active">Accueil</a></li>
          <li><a href="#about">À propos</a></li>
          <ul>
              <li className="dropdown"><a href="#features"><span>Ateliers</span> <i className="bi bi-chevron-down toggle-dropdown"></i></a>
                <ul>
                  <li><a href="#">Apprentissage</a></li>
                  <li><a href="#">Porte ouvertes</a></li>
                </ul>
              </li>
            </ul>
      
          <li><a href="#soutenir">Soutenir</a></li>
          <li><a href="#Contact">Contact</a></li>
        </ul>
        <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
      </nav>
      
      <a className="btn-getstarted" href="#adhérer">Adhérer</a>
      <div id="google_translate_element"></div>
      
      
    </div>

    
  </header>
   
</>
      
    );
}


